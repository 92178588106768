.navigation_container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2%;
    margin-bottom: 20px;
}

.navigation_icon_path1:before {
    color: #0D065D!important;
    font-size: 20px;
}

.navigation_icon_path2:before {
    font-size: 20px;
}

.navigation_text {
    font-family: Oswald;
    font-style: italic;
    font-weight: 600;
    /*font-size: 36px;*/
    line-height: 70px;

    color: #0D065D;

    margin: 0 10px;
}