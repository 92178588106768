
.app__gallery_container {
    background-color: #FFFFFF;
}

.app__gallery_wrapper {
    padding: 60px 0;
}

.app__gallery_title_container {
    margin-bottom: -65px;
}

.app__gallery_title_container div {
    margin: auto;
    text-align: center;
}