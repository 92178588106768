.app__locations{
    background: rgba(233, 211, 183, 0.5);
}

.app__locations_title {
    width: 100%;
    text-align: center;
    padding-top: 5%;
}

.app__locations_title div {
    margin: auto;
}

.app__locations_title h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 70px;
    /* identical to box height, or 194% */

    color: #E1B236;
}

.app__locations_detail_wrapper {
    margin: 37px 25% 0 25%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 40px 20px;
    justify-items: center;
}

.app__location {
    margin-left: 17px;
    margin-right: 17px;
}

.app__locations_button_wrapper {
    text-align: center;
    margin-top: 37px;
    padding-bottom: 8%;
}

@media screen and (max-width: 690px) {
    .app__locations_detail_wrapper {
        margin-left: 0;
        margin-right: 0;
    }
}