.app__location {
    width: 283px;
    /*height: 275px;*/

    background: #FFFFFF;
    box-shadow: 5px 5px 20px 10px rgba(168, 74, 0, 0.1);
    border-radius: 8px;
    text-align: left;
}

.app__location_image {
    border-radius: 8px 8px 0 0;
}

.app__location_name {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    color: #0D065D;

    margin-left: 20px;
    margin-top: 10px;
}

.app__location_address {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 11px;
    line-height: 15px;
    /* identical to box height */
    color: #000000;
    margin-left: 20px;
}

.app__location_bottom_wrapper {

    display: flex;
    justify-content: space-between;
    padding: 20px;
}

.app__location_bottom_wrapper p {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 167% */
    color: #4B4B4B;
}