.container{
    background: rgba(233, 211, 183, 0.5);
    padding: 0 0 10% 0;
}

.title {
    width: 100%;
    text-align: center;
    padding-top: 5%;
}

.title div {
    margin: auto;
}

.title h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 70px;
    /* identical to box height, or 194% */

    color: #E1B236;
}

.wrapper {
    justify-items: center;
}

.image {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: absolute;
}