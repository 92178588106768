.embla {
}
.embla__viewport {
    overflow: hidden;
    width: 100%;
}
.embla__container {
    display: flex;
}
.embla__slide {
    position: relative;
    flex: 0 0 auto;
    margin: 0 1px;
}
