.app__header {
    position: relative;
    width: 100%;
    text-align: center;
    background-color: rgba(233, 211, 183, 0.5);
    padding: 35vh 0 30vh 0;
}

.app__header_video_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
    z-index: 1;
}

.app__header_content {
    position: relative;
    width:fit-content;
    width:-webkit-fit-content;
    width:-moz-fit-content;
    margin: auto;
    z-index: 2;
}

.app__header_title {
    width: 100%;
    height: auto;

    font-family: Oswald;
    font-style: normal;
    font-weight: bold;
    font-size: 48px;
    line-height: 71px;
    text-align: center;

    color: #FFFFFF;

    flex-direction: column;
}

.app__header_subtitle {
    width: 100%;
    margin: 24px 0;

    font-family: Economica;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    line-height: 30px;
    /* identical to box height, or 167% */

    text-align: center;
    letter-spacing: 0.08em;

    color: #EDFFFA;
}
.app__header_location_btn_wrapper {
    width: 100%;
    text-align: center;

    padding: 0 10px 0 10px;
}


.app__header_order_btn_wrapper {
    width: 100%;

    text-align: center;
    margin-top: 20px;
    padding: 0 10px 0 10px;
    display: flex;
    justify-content: space-between;
}

.flex_center {
    justify-content: center;
}


.custom__location_btn {
}

.app__header_icon:before {
    color: white;
    font-size: 30px;
    vertical-align: middle;
}


.app__header_order_btn_wrapper button span {
    margin-left: 11px;
    vertical-align: middle;
}
