.app__offering {
    width: 216px;
    /*height: 275px;*/
    background: #FFFFFF;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 5px 5px 20px 10px rgba(168, 74, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    position: relative;
    margin-top: 75px;
    margin-left: 17px;
    margin-right: 17px;
    /* display: flex; */
}

.app__offering_image {
    height: 173px;
    width: 173px;
    overflow:hidden;
    background: #C4C4C4;

    position: relative;
    top: -75px;
    margin: auto;
    /* border: 1px solid #000000; */
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    text-align: center;
}

.app__offering_image img {
    object-fit:cover;
}

.app__offering_text {
    top: -75px;
    position: relative;
    text-align: center;
}

.app__offering_title {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
    color: #0D065D;
    margin-top: 17px;
    margin-bottom: 5px;
}

.app__offering_description {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;

    color: #000000;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 27px;
    margin-right: 27px;
}

.app__offering_serves {
    font-family: Noto Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    color: #998062;
    position: absolute;
    bottom: 17px;
    text-align: center;
    width: 100%;
}
