.app__featured_wrapper {
    background-color: white;
    padding-top: 10%;
    padding-bottom: 120px;
}

.app__featured_offerings_wrapper {
    margin: 37px 25% 0 25%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    grid-gap: 20px 20px;
    grid-auto-rows: 1fr;
    align-items: stretch;
    justify-items: center;
}

.app__featured_title {
    width: 100%;
    text-align: center;
}

.app__featured_title div {
    margin: auto;
}

.app__featured_title h1 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 70px;
    /* identical to box height, or 194% */


    color: #E1B236;
}

.app__featured_offering {
    margin: 17px;
}

.app__featured_button_wrapper {
    text-align: center;
    margin-top: 37px;
}

@media screen and (max-width: 690px) {
    .app__featured_offerings_wrapper {
        margin-left: 0;
        margin-right: 0;
    }
}
